<template>
  <div class="message-history" ref="messageContainer">
    <div v-for="(item, index) in messages" :key="index" class="message-item" :class="{'user-message': item.sender === 'user', 'system-message': item.sender === 'system'}">
      <p>{{ item.content }}</p>
    </div>
  </div>
</template>

<script>
import { generateAudio } from '../api'; // Ajusta la ruta según la ubicación de tu archivo api.js

export default {
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    isAudioEnabled: {
      type: Boolean,
      default: false // Asumimos que por defecto el audio no está habilitado
    }
  },
  data() {
    return {
      currentAudio: null, // Almacena la referencia al objeto Audio actual
    };
  },
  watch: {
    messages: {
      handler(newMessages = [], oldMessages = []) {
        this.$nextTick(() => {
          this.scrollToBottom();
          if (newMessages.length && oldMessages.length) {
            const latestMessage = newMessages[newMessages.length - 1];
            if (latestMessage && latestMessage.sender === 'system' && this.isAudioEnabled) {
              this.playAudio(latestMessage.content);
            }
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      } else {
        console.error('El contenedor de mensajes no fue encontrado');
      }
    },
    async playAudio(text) {
      if (!this.isAudioEnabled) return; // No proceder si el audio está desactivado

      if (this.currentAudio && !this.currentAudio.paused) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0; // Reinicia el audio actual
      }

      try {
        const audioBlob = await generateAudio(text);
        if (audioBlob.size === 0) {
          console.error('El archivo de audio está vacío');
          return;
        }
        
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        this.currentAudio = audio;

        audio.onloadedmetadata = async () => {
          try {
            await audio.play();
            console.log('Reproducción de audio iniciada');
          } catch (error) {
            console.error('Error al intentar reproducir el audio automáticamente:', error);
          }
        };

        audio.onerror = (e) => {
          console.error('Error al reproducir el audio:', e);
        };

        audio.onended = () => {
          console.log('Reproducción de audio completada');
          this.currentAudio = null; // Limpia la referencia cuando el audio termina
        };
      } catch (error) {
        console.error('Error generando audio:', error);
      }
    }
  },
  mounted() {
    this.scrollToBottom();
  }
};
</script>

<style scoped>
.message-history {
  position: fixed;
  top: 15vh;
  bottom: 10%;
  width: 100%;
  overflow-y: auto;
  background-image: url('@/assets/images/business-background.png'); /*  Ruta de la imagen de fondo */
  background-color: rgba(75, 63, 114, 0.9); /* Color de fondo oscuro con transparencia */
  background-blend-mode: multiply; /* Mezcla el color de fondo con la imagen */
  background-size: cover; /* Asegura que la imagen cubra completamente el área */
  background-position: center; /* Centra la imagen en el contenedor */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}
.message-item {
  margin-bottom: 40px;
  color: #f5f5dc;
  border-radius: 10px;
  width: 70%;
  max-width: 700px;
  min-width: 200px;
  word-wrap: break-word;
  box-sizing: border-box;
}

.user-message, .system-message {
  background-color: rgba(26, 13, 79, 0.7); /* Transparente para permitir ver el fondo */
  color: #d3af37; /* Ajusta los colores según necesites para visibilidad */
  text-align: right;
  padding: 2px 14px;
  margin-left: auto;
  margin-right: 10px;
  border-radius: 38px;
  max-width: 70%;
  word-wrap: break-word;
  display: inline-block;
  clear: both;
  text-align: center;
}

.system-message {
  color: #fff;
  margin-right: auto;
  margin-left: 10px;
}
</style>