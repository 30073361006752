<template>
  <div class="image-uploader">
    <input type="file" id="file" @change="handleFileUpload" accept="image/*" class="file-input" />
    <label for="file" class="icon-label">
      <img src="@/assets/icons/select-icon.png" alt="Select file"> <!-- Asumiendo que tienes un icono para seleccionar archivo -->
    </label>
    <button @click="emitImage" :disabled="!file" class="icon-button">
      <img src="@/assets/icons/upload-icon.png" alt="Upload file"> <!-- Asumiendo que tienes un icono para subir archivo -->
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    emitImage() {
      if (!this.file) {
        alert("Por favor, selecciona una imagen primero.");
        return;
      }

      // Emitimos el archivo al componente padre para que sea manejado allí
      this.$emit('image-uploaded', this.file);
    }
  }
};
</script>

<style scoped>
.image-uploader {
  position: fixed;
  bottom: 50px;
  left: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(75, 63, 114, 0.7); /* Color y transparencia del estilo speech-bubble */
  border-radius: 50px;
  padding: 40px 0;
  margin: 10px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: float-uploader 3.5s ease-in-out infinite;
}

.file-input {
  display: none;
}

.icon-label, .icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.icon-label img, .icon-button img {
  width: 20px; /* Ajusta según el tamaño de tus iconos */
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.icon-button:disabled img {
  filter: grayscale(100%); /* Icono gris cuando está deshabilitado */
}

.icon-label:hover img, .icon-button:hover img {
  transform: scale(1.1); /* Efecto de crecimiento al pasar el mouse */
}

@keyframes float-uploader {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}
</style>
