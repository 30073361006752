<template>
  <div class="app-container">
    <!-- Logo centrado en la parte superior -->
    <img :src="require('@/assets/logo.png')" alt="Logo" class="logo">

    <h1 class="title">BIENVENIDO A LA ERA DE LA IA</h1>
    
    <!-- Botón para iniciar la conversación -->
    <button @click="startConversation" :disabled="loading" class="start-button">
      Iniciar Conversación
    </button>

    <!-- Video en tiempo real de la réplica -->
    <div v-if="conversationUrl" class="iframe-container">
      <iframe :src="conversationUrl" allow="camera; microphone" frameborder="0"></iframe>
      <div class="overlay-info top-right">
        Tiempo restante: 1:43
      </div>
      <div class="overlay-info bottom-left">
        Réplica conectada
      </div>
    </div>

    <!-- Captura de voz -->
    <button v-if="conversationStarted && !listening" @click="startListening" class="listen-button">
      Habla con la réplica
    </button>
    
    <div v-if="listening" class="listening-status">Escuchando...</div>

    <!-- Mensajes de estado y error -->
    <div v-if="loading" class="loading-status">Cargando...</div>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      conversationUrl: null,  // Almacenará la URL del video en vivo
      conversationId: null,   // Almacenará el ID de la conversación
      loading: false,
      listening: false,
      conversationStarted: false,
      errorMessage: null,
      recognition: null,      // Web Speech API
    };
  },
  methods: {
    // Inicia la conversación con la réplica
    async startConversation() {
      this.loading = true;
      this.errorMessage = null;
      
      try {
        const response = await axios.post('https://brainstream.pro/api/start-conversation');
        this.conversationUrl = response.data.conversation_url;
        this.conversationId = response.data.conversation_id;
        this.conversationStarted = true;
      } catch (error) {
        this.errorMessage = 'Error al iniciar la conversación.';
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    // Iniciar la captura de voz usando Web Speech API
    startListening() {
      if (!('webkitSpeechRecognition' in window)) {
        this.errorMessage = 'La API de reconocimiento de voz no es compatible con este navegador.';
        return;
      }

      // eslint-disable-next-line no-undef
      this.recognition = new webkitSpeechRecognition();
      this.recognition.lang = 'es-ES'; // Configurar el idioma, puedes cambiarlo según tus necesidades
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;

      this.recognition.onstart = () => {
        this.listening = true;
      };

      this.recognition.onerror = (event) => {
        this.errorMessage = `Error en el reconocimiento de voz: ${event.error}`;
        this.listening = false;
      };

      this.recognition.onend = () => {
        this.listening = false;
      };

      this.recognition.onresult = async (event) => {
        const userText = event.results[0][0].transcript;
        console.log('Texto capturado:', userText);

        // Enviar la entrada de voz al backend para ser procesada por OpenAI
        await this.sendToOpenAI(userText);
      };

      // Iniciar el reconocimiento de voz
      this.recognition.start();
    },

    // Enviar el texto capturado por la voz a OpenAI a través del backend
    async sendToOpenAI(userText) {
      try {
        const response = await axios.post('https://brainstream.pro/api/send-to-openai', {
          user_prompt: userText,
          thread_id: this.conversationId
        });

        // Enviar la respuesta de OpenAI a la réplica
        await this.sendToReplica(response.data.response);
      } catch (error) {
        this.errorMessage = 'Error al procesar la solicitud.';
        console.error(error);
      }
    },

    // Enviar la respuesta de OpenAI a la réplica
    async sendToReplica(responseText) {
      try {
        await axios.post('https://brainstream.pro/api/send-to-replica', {
          conversation_id: this.conversationId,
          response: responseText
        });

        console.log('Respuesta enviada a la réplica:', responseText);
      } catch (error) {
        this.errorMessage = 'Error al enviar la respuesta a la réplica.';
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
.app-container {
  text-align: center;
  padding: 20px;
  background: linear-gradient(135deg, #6B46C1, #0F0C29); /* Fondo degradado */
  min-height: 100vh; /* Asegurar que el contenedor ocupe toda la altura de la pantalla */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Color del texto */
}

.logo {
  width: 450px;
  margin-bottom: 20px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.start-button,
.listen-button {
  background-color: #6B46C1;
  color: white;
  padding: 12px 24px;
  border-radius: 999px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.start-button:hover,
.listen-button:hover {
  background-color: #9B57F3;
}

.iframe-container {
  position: relative;
  width: 100%;
  max-width: 960px; /* Ancho máximo para widescreen */
  padding-top: 56.25%; /* Aspect ratio 16:9 */
  margin: 20px 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  border: 0;
}

.overlay-info {
  position: absolute;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 999px;
  font-size: 0.9rem;
}

.top-right {
  top: 10px;
  right: 10px;
}

.bottom-left {
  bottom: 10px;
  left: 10px;
}

.listening-status,
.loading-status {
  font-size: 1.2rem;
  margin-top: 20px;
}

.error {
  color: red;
  margin-top: 20px;
  font-size: 1.2rem;
}
</style>
