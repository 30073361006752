<template>
  <div>
    <div v-if="viaje" class="detalle-viaje">
      <h1>{{ viaje.titulo }}</h1>
      <p>{{ viaje.descripcion }}</p>
      <div v-for="imagen in viaje.imagenes" :key="imagen" class="imagen-viaje">
        <img :src="imagen" :alt="`Imagen de ${viaje.titulo}`" />
      </div>
    </div>
    <div v-else>
      <!-- Cambiado para mostrar un mensaje más descriptivo en caso de error -->
      <p>{{ error || "Cargando viaje..." }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      viaje: null,
      error: null, // Agregado para manejar los errores de visualización
    };
  },
  async mounted() {
    console.log(`Intentando cargar el viaje con ID: ${this.id}`); // Registro para depuración
    try {
      const url = `/viajes/viaje${this.id}.json`;
      console.log(`Solicitando JSON desde: ${url}`); // Registro para depuración
      const response = await fetch(url);
      if (response.ok) {
        this.viaje = await response.json();
        console.log('Viaje cargado:', this.viaje); // Registro para depuración
      } else {
        // Actualización del manejo de errores para guardar el estado y mostrarlo en la plantilla
        this.error = `Error al cargar el viaje: el servidor respondió con el estado ${response.status}.`;
        console.error(this.error);
      }
    } catch (error) {
      this.error = 'Error al cargar los datos del viaje: ' + error.message;
      console.error(this.error);
    }
  },
};
</script>

<style>
/* Tus estilos aquí */
.detalle-viaje {
  /* Estilos para el detalle del viaje */
}
.detalle-viaje .imagen-viaje {
  /* Estilos para las imágenes del viaje */
}
</style>
