<template>
  <div class="chat-background">
    <div class="chat-container">
      <div class="chat-header">
        <img src="@/assets/triunfa/logo-triunfa.png" alt="Triunfa con tu Libro" class="logo" />
        <h2>SuperAsistente IA WriterPRO</h2>
      </div>
      <div class="chat-body" ref="chatBody">
        <div v-for="(message, index) in messages" :key="index" :class="['chat-message', message.sender]">
          <div v-if="message.sender === 'bot'" class="bot-message">
            <p v-html="formatBotMessage(message.content)"></p>
          </div>
          <div v-else class="user-message">
            <p>{{ message.content }}</p>
          </div>
        </div>
      </div>
      <div class="chat-footer">
        <input 
          type="text" 
          v-model="userMessage" 
          placeholder="Escribe tu mensaje aquí..." 
          @keypress="handleKeyPress"
        />
        <button @click="sendMessage">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userMessage: '',
      messages: [],
      threadId: null
    };
  },
  methods: {
    sendMessage() {
      if (this.userMessage.trim() !== '') {
        this.appendMessage('user', this.userMessage);
        const messageToSend = this.userMessage;
        this.userMessage = '';

        const requestData = this.threadId
          ? { prompt: messageToSend, thread_id: this.threadId }
          : { prompt: messageToSend };

        fetch('https://brainstream.pro/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        })
          .then(response => response.json())
          .then(data => {
            if (data.response) {
              this.appendMessage('bot', data.response);
              if (!this.threadId) {
                this.threadId = data.thread_id;
              }
            } else {
              this.appendMessage('bot', 'Lo siento, no pude procesar tu solicitud.');
            }
          })
          .catch(error => {
            console.error('Error:', error);
            this.appendMessage('bot', 'Error al comunicarse con el servidor.');
          });
      }
    },
    appendMessage(sender, content) {
      this.messages.push({ sender, content });
      this.$nextTick(() => {
        const chatBody = this.$refs.chatBody;
        chatBody.scrollTop = chatBody.scrollHeight;
      });
    },
    handleKeyPress(event) {
      if (event.key === 'Enter') {
        this.sendMessage();
      }
    },
    formatBotMessage(message) {
      return message
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/(\d+)\./g, '<strong>$1.</strong>')
        .replace(/\n/g, '<br>');
    }
  },
  mounted() {
    // Prevenir zoom en iOS
    document.addEventListener('gesturestart', function(e) {
      e.preventDefault();
    });
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow: hidden;
  position: fixed;
  width: 100%;
  -webkit-text-size-adjust: 100%;
}

.chat-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #4a90e2;
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.logo {
  width: 20%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
  margin-bottom: 60px; /* Ajusta esto según la altura de tu chat-footer */
}

.chat-footer {
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: white;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px; /* Ajusta según sea necesario */
}

.chat-footer input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.chat-footer button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.chat-message.user {
  background-color: #4a90e2;
  color: white;
  align-self: flex-end;
  margin-left: auto;
}

.chat-message.bot {
  background-color: #e0e0e0;
  color: black;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .logo {
    width: 30%;
  }
  .chat-header h2 {
    font-size: 1.2em;
  }
  .chat-footer input[type="text"], .chat-footer button {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 50%;
  }
  .chat-header h2 {
    font-size: 1em;
  }
  .chat-footer input[type="text"], .chat-footer button {
    font-size: 16px;
  }
}
</style>