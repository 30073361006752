<template>
  <div>
    <video ref="videoElement" width="720" height="560" autoplay></video>
    <canvas ref="canvasElement" width="720" height="560"></canvas>
    <div v-if="cameraError" class="error-message">
      Error: No se pudo acceder a la cámara. Por favor, verifica los permisos y recarga la página.
    </div>
  </div>
</template>

<script>
import * as faceapi from 'face-api.js';
console.log('faceapi is defined:', faceapi);

export default {
  name: "CameraComponent",

  props: {
    isActive: Boolean // Propiedad para controlar la activación de la cámara
  },

  data() {
    return {
      cameraError: false, // Estado para manejar errores de la cámara
      intervalId: null // Almacena el ID del intervalo para poder detenerlo más tarde
    };
  },

  watch: {
    isActive(newVal) {
      console.log('isActive changed:', newVal); // Registro del cambio de estado
      if (newVal) {
        this.startVideo();
      } else {
        this.stopVideo();
      }
    }
  },

  methods: {

    async startVideo() {
      console.log('Starting video...'); // Indica el inicio del proceso de video
      try {
        console.log('Loading Tiny Face Detector...');
        await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
        console.log('Tiny Face Detector loaded successfully');

        console.log('Loading SSD Mobilenet V1...');
        await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
        console.log('SSD Mobilenet V1 loaded successfully');

        console.log('Loading Face Landmark 68 Model...');
        await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
        console.log('Face Landmark 68 Model loaded successfully');

        console.log('Loading Face Expression Recognition Model...');
        await faceapi.nets.faceExpressionNet.loadFromUri('/models');
        console.log('Face Expression Recognition Model loaded successfully');

        console.log('Loading Age and Gender Recognition Model...');
        await faceapi.nets.ageGenderNet.loadFromUri('/models');
        console.log('Age and Gender Recognition Model loaded successfully');




        this.initializeCamera();
      } catch (e) {
        console.error("Failed to load one or more face-api models:", e);
        this.cameraError = true;
      }
    },





    initializeCamera() {
      console.log('Initializing camera...');
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          console.log('Camera activated');
          this.$refs.videoElement.srcObject = stream;
          this.detectFaces();
        })
        .catch(error => {
          console.error("Failed to access the webcam:", error);
          this.cameraError = true;
        });
    },

    detectFaces() {
      console.log('Detecting faces...');
      const videoEl = this.$refs.videoElement;
      const canvas = this.$refs.canvasElement;
      const displaySize = { width: videoEl.width, height: videoEl.height };
      faceapi.matchDimensions(canvas, displaySize);
      let lastDetectedFaces = []; // Guarda los datos de las últimas caras detectadas

      this.intervalId = setInterval(async () => {
        const detections = await faceapi.detectAllFaces(videoEl, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withAgeAndGender()
          .withFaceExpressions();
        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        const currentFaces = resizedDetections.map(d => ({
          age: parseInt(d.age, 10),
          gender: d.gender,
          genderProbability: parseFloat((d.genderProbability * 100).toFixed(2)),
          expression: Object.keys(d.expressions).reduce((a, b) => d.expressions[a] > d.expressions[b] ? a : b),
          expressionProbability: parseFloat((Math.max(...Object.values(d.expressions)) * 100).toFixed(2))
        }));

        const newFaces = currentFaces.filter(face => !lastDetectedFaces.some(f => 
          f.age === face.age && 
          f.gender === face.gender &&
          f.expression === face.expression
        ));

        if (newFaces.length > 0) {
          newFaces.forEach(face => {
            fetch('/node-api/save-face-data', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(face)
            })
            .then(response => response.json())
            .then(data => console.log('Data sent successfully:', data))
            .catch(err => console.error('Error sending data:', err));
          });

          lastDetectedFaces = currentFaces; // Actualizar las caras detectadas recientemente
        }
      }, 5000); // Ajusta este tiempo según sea necesario
    },





    stopVideo() {
      console.log('Stopping video...');
      if (this.$refs.videoElement && this.$refs.videoElement.srcObject) {
        this.$refs.videoElement.srcObject.getTracks().forEach(track => track.stop());
        console.log('Video stopped');
        this.$refs.videoElement.srcObject = null;
      }
      if (this.intervalId) {
        clearInterval(this.intervalId);
        console.log('Detection interval stopped');
      }
      this.cameraError = false; // Reset camera error state
    }
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 14px;
}
</style>
