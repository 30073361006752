<template>
  <div class="prompt-component">
    <div class="profile-picture">
      <!-- Asegúrate de que la ruta a la imagen sea correcta -->
      <img :src="require('@/assets/images/logo-digitalmind.png')" alt="Foto de perfil">
    </div>
    <h2>Prueba nuestra herramienta</h2>
    <textarea v-model="userPrompt" placeholder="Escribe algo..." @keyup.enter="sendPrompt" :disabled="isLoading" autofocus></textarea>
    <button :disabled="isLoading" @click="sendPrompt">
      <span v-if="isLoading" class="loading-dots"></span>
      {{ isLoading ? '' : 'Enviar' }}
    </button>
    <transition name="fade">
      <div v-if="response" class="response">
        <h3>Respuesta:</h3>
        <p>{{ response }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userPrompt: '',
      response: null,
      isLoading: false, // Usar solo isLoading para manejar tanto el envío como la carga de la respuesta
      loadingText: 'Enviando...',
      threadId: null,
      retryTimeoutId: null,
      startTime: null,
      maxDuration: 180000, // 3 minutos
    };
  },
  methods: {
    async startConversation() {
      if (this.threadId) return;
      this.isLoading = true;
      try {
        const response = await fetch('https://brainstream.pro/api/test/starteo', { method: 'GET' });
        if (response.ok) {
          const data = await response.json();
          this.threadId = data.thread_id;
        } else {
          this.response = 'Error al iniciar la conversación: ' + response.statusText;
          this.isLoading = false; // Asegurarse de cambiar el estado aquí también si hay un error
        }
      } catch (error) {
        this.response = 'Error al iniciar la conversación: ' + error.message;
        this.isLoading = false; // Asegurarse de cambiar el estado aquí también si hay un error
      }
    },
    async sendPrompt() {
      if (!this.userPrompt.trim()) {
        this.response = 'Por favor, escribe algo antes de enviar.';
        return;
      }
      this.isLoading = true; // Activar al enviar
      if (!this.threadId) await this.startConversation();
      try {
        const payload = { thread_id: this.threadId, message: this.userPrompt };
        const response = await fetch('https://brainstream.pro/api/test/chateo', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
        if (response.ok) {
          const data = await response.json();
          this.startTime = Date.now(); // Inicia el contador de tiempo
          this.checkRunStatus(data.run_id);
        } else {
          this.response = 'Hubo un problema con la respuesta del servidor: ' + response.statusText;
          this.isLoading = false;
        }
      } catch (error) {
        this.response = 'Error al enviar el mensaje: ' + error.message;
        this.isLoading = false;
      }
    },
    async checkRunStatus(runId) {
      if (!this.threadId || !runId) {
        this.response = "Falta información del hilo o de la ejecución.";
        this.isLoading = false;
        return;
      }
      if (!this.startTime) this.startTime = Date.now();
      const elapsedTime = Date.now() - this.startTime;

      if (elapsedTime < this.maxDuration) {
        try {
          const response = await fetch(`https://brainstream.pro/api/test/checkeo`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ thread_id: this.threadId, run_id: runId }),
          });
          const data = await response.json();
          if (data.status === 'completed') {
            this.response = data.response;
            this.isLoading = false; // Desactivar isLoading aquí
            this.startTime = null; // Reset para la próxima comprobación
          } else {
            // No desactivar isLoading aquí, espera al siguiente chequeo
            this.retryTimeoutId = setTimeout(() => this.checkRunStatus(runId), 5000); // Esperar 5 segundos antes de reintentar
          }
        } catch (error) {
          this.response = `Error al verificar el estado: ${error.message}`;
          this.isLoading = false; // Asegúrate de desactivar isLoading en caso de error
          this.startTime = null; // Reset debido a un error
        }
      } else {
        this.response = 'La respuesta está tardando demasiado. Intenta nuevamente más tarde.';
        this.isLoading = false; // Desactivar isLoading aquí también
        this.startTime = null; // Reset para la próxima comprobación
      }
    },
    clearRetryTimeout() {
      if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId);
      this.retryTimeoutId = null;
    }
  },
  beforeUnmount() {
    this.clearRetryTimeout();
  }
};
</script>

<style scoped>
.prompt-component {
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea los elementos internos al centro */
  background-image: linear-gradient(to bottom, rgba(69, 31, 111, 0.7), rgba(69, 31, 111, 0.7)), url('~@/assets/images/fondo-candidato.jpg');
  background-size: cover;
  color: #f5f5dc; /* Texto en crema suave para contraste */
  font-family: 'Nunito', sans-serif;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  margin: auto;
}

.prompt-component h2, .response h3, .response p {
  text-align: center; /* Asegura que el texto esté centrado */
}

textarea, button, .response, .profile-picture {
  width: calc(100% - 30px); /* Ajusta el ancho considerando el padding */
  margin-bottom: 20px; /* Proporciona espaciado uniforme */
}

textarea {
  min-height: 150px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 16px;
  border: 1px solid #d4af37; /* Bordes en dorado */
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;
}

textarea:focus {
  outline: none;
  border-color: #a1d6a3; /* Enfoque en verde menta para destacar */
  background: #FFFFFF;
}

button {
  position: relative; /* Posición relativa para la animación de carga */
  padding: 15px 0;
  font-size: 18px;
  background-color: #d4af37; /* Botones en dorado */
  color: #451f6f; /* Texto del botón en el color de fondo principal */
  border: none;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
}

button:hover, button:focus {
  background-color: #b39ddb; /* Oscurecer a lavanda al pasar el mouse o enfocar */
  outline: none;
}

.loading-dots {
  position: absolute; /* Posición absoluta para centrarlo en el botón */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.response {
  background-color: rgba(69, 31, 111, 0.8); /* Fondo semitransparente que combina con el principal */
  color: #f5f5dc; /* Texto en crema para contraste */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  line-height: 1.6;
}

.profile-picture {
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #d4af37; /* Bordes en dorado */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.profile-picture img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Transiciones suaves para las respuestas */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active enversiones <2.1.8 */ {
  opacity: 0;
}
</style>

