<template>
  <div class="face-scan-container">
    <h1>Escaneo Facial</h1>
    <p>Por favor, posiciona tu rostro frente a la cámara.</p>
    <div class="video-canvas-container">
      <camera-component :is-active="scanning" @cameraError="handleCameraError" />
    </div>
    <div v-if="cameraError" class="error-message">
      Error: No se pudo acceder a la cámara. Por favor, verifica los permisos y recarga la página.
    </div>
    <button @click="toggleScanning">
      {{ scanning ? 'Detener Escaneo' : 'Iniciar Escaneo' }}
    </button>
  </div>
</template>

<script>
import CameraComponent from './CameraComponent.vue';

export default {
  components: {
    CameraComponent
  },
  data() {
    return {
      scanning: false,
      cameraError: false
    };
  },
  methods: {
    toggleScanning() {
      this.scanning = !this.scanning;
    },
    handleCameraError(status) {
      this.cameraError = status;
      if (status) {
        this.scanning = false; // Stop scanning if there is a camera error
      }
    }
  }
};
</script>

<style>
.face-scan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px; /* Añadido padding para que el contenido no esté pegado a los bordes */
}

.media-container {
  display: grid;
  grid-template-columns: 1fr; /* Una columna en dispositivos móviles */
  grid-gap: 10px; /* Espacio entre los elementos */
  width: 100%;
}

.video-container, .canvas-container {
  width: 100%;
}

.video-container > video, .canvas-container > canvas {
  width: 100%;
  height: auto; /* Para que el contenido se ajuste proporcionalmente */
  max-width: 100%; /* Para que no se desborde en pantallas pequeñas */
}

.error-message {
  color: red;
  margin: 20px 0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media screen and (min-width: 768px) {
  .media-container {
    grid-template-columns: repeat(2, 1fr); /* Dos columnas en dispositivos mayores a 768px */
  }
}
</style>
