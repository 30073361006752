<template>
  <div>
    <video ref="videoElement" width="720" height="560" autoplay></video>
    <canvas ref="canvasElement" width="720" height="560"></canvas>
    <div v-if="cameraError" class="error-message">
      Error: No se pudo acceder a la cámara. Por favor, verifica los permisos y recarga la página.
    </div>
    <!-- Agregado para mostrar la información decodificada del QR -->
    <div v-if="decodedQRInfo">
      Decoded QR Info: {{ decodedQRInfo }}
    </div>
  </div>
</template>

<script>
import jsQR from 'jsqr';

export default {
  name: "QRCodeScanner",

  props: {
    isActive: Boolean
  },

  data() {
    return {
      cameraError: false,
      qrCode: null,
      intervalId: null,
      decodedQRInfo: '' // Variable para almacenar y mostrar la información del QR
    };
  },

  watch: {
    isActive(newVal) {
      if (newVal) {
        this.startVideo();
      } else {
        this.stopVideo();
      }
    }
  },

  methods: {
    async startVideo() {
      try {
        this.initializeCamera();
      } catch (e) {
        console.error("Failed to initialize camera:", e);
        this.cameraError = true;
      }
    },

    initializeCamera() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          this.$refs.videoElement.srcObject = stream;
          this.scanQRCode();
        })
        .catch(error => {
          console.error("Failed to access the webcam:", error);
          this.cameraError = true;
        });
    },

    scanQRCode() {
      const video = this.$refs.videoElement;
      const canvas = this.$refs.canvasElement;
      const ctx = canvas.getContext('2d');

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });

        if (code) {
          this.qrCode = code.data;
          clearInterval(this.intervalId); // Detener el intervalo una vez que el QR ha sido escaneado
          this.performActionBasedOnQR(this.qrCode);
        }
      }, 100);
    },

    performActionBasedOnQR(data) {
      this.decodedQRInfo = data; // Actualiza la información decodificada en la interfaz de usuario
      console.log("QR Data:", data); // Opcional: también mostrar en la consola
    },

    stopVideo() {
      if (this.$refs.videoElement.srcObject) {
        this.$refs.videoElement.srcObject.getTracks().forEach(track => track.stop());
        this.$refs.videoElement.srcObject = null;
      }
    }
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 14px;
}
</style>
