<template>
  <div class="profile-container" :style="backgroundStyle">
    <div class="robot-container">
      <img :src="robotImage" alt="My Digital Mind" class="floating-robot">
    </div>
    <div class="text-and-bubble">
      <div class="speech-bubble">
        <div class="content-container">
          <p>MyDigitalMind</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'MessageProfile',
  data() {
    return {
      robotImage: require('@/assets/images/personajes/digitalmind-01.png'), // Verifica la ruta
    };
  },
  computed: {
    backgroundStyle() {
      // Genera dos colores al azar en formato RGBA con transparencia
      const color1 = `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, 0)`;
      const color2 = `rgba(${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, ${Math.floor(Math.random()*256)}, 0)`;
      return {
        background: `linear-gradient(to bottom, ${color1}, ${color2})`
      };
    }
  }
};
</script>

<style scoped>
.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 20%;
  z-index: 10;
}

.robot-container, .text-and-bubble {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.floating-robot {
  width: 50px;
  max-width: 25%;
  animation: float-robot 3s ease-in-out infinite;
}

.speech-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(75, 63, 114, 0.7);
  border-radius: 100px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: float-bubble 3.5s ease-in-out infinite;
  width: 95%; /* Reduce el ancho para asegurar margen dentro del contenedor padre */
}

.content-container {
  display: flex;
  align-items: center; /* Alinea verticalmente */
  justify-content: center; /* Alinea horizontalmente */
  margin: 5px; /* Añade margen para evitar que el contenido toque los bordes de speech-bubble */
}

.logo-image {
  width: 70px;
  height: auto;
  margin-right: 10px; /* Espacio entre el logo y el texto */
}

.speech-bubble p {
  font-size: 1em; /* Ajusta el tamaño de la fuente según necesario */
  color: white; /* Asegura que el texto sea blanco */
  font-weight: bold; /* Aplica negrita al texto */
  margin: 0; /* Elimina margenes por defecto para asegurar alineación correcta */
}

@keyframes float-robot {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes float-bubble {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}
</style>
