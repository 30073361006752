<template>
  <div class="audio-toggle">
    <button @click="toggleAudio">
      <img :src="isAudioEnabled ? require('@/assets/icons/audio-on.png') : require('@/assets/icons/audio-off.png')" alt="Toggle Audio Icon" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAudioEnabled: true
    };
  },
  methods: {
    toggleAudio() {
      this.isAudioEnabled = !this.isAudioEnabled;
      this.$emit('audio-toggle', this.isAudioEnabled);
    }
  }
};
</script>

<style>
.audio-toggle button {
  padding: 10px;
  background-color: transparent; /* Hacer el fondo transparente para mostrar solo el ícono */
  border: none;
  cursor: pointer;
}
.audio-toggle button img {
  width: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  height: 24px;
}
.audio-toggle button:hover {
  opacity: 0.8; /* Añadir un efecto de opacidad en hover */
}
</style>
