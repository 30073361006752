<template>
  <div class="chat-app">
    <div class="audio-toggle-container">
      <audio-toggle @audio-toggle="toggleAudio"></audio-toggle>
    </div>
    <message-profile :profileImage="profileImageUrl"></message-profile>
    <message-history :messages="messages" :isAudioEnabled="isAudioEnabled"></message-history>
    <button @click="toggleImageUploader" class="toggle-uploader-btn"></button>
    <image-uploader v-if="isUploaderVisible" @image-uploaded="handleImageUpload"></image-uploader>
    <message-input :isLoading="isLoading" @send="sendPrompt"></message-input>
    <voice-input @send="sendPrompt"></voice-input>
  </div>
</template>


<script>
import MessageProfile from './MessageProfile.vue';
import MessageHistory from './MessageHistory.vue';
import MessageInput from './MessageInput.vue';
import VoiceInput from './VoiceInput.vue';
import ImageUploader from './ImageUploader.vue';
import AudioToggle from './AudioToggle.vue'; // Importar el componente de toggle de audio

export default {
  components: {
    MessageProfile,
    MessageHistory,
    MessageInput,
    VoiceInput,
    ImageUploader,
    AudioToggle // Registrar el componente de toggle de audio
  },
  data() {
    return {
      messages: [],
      isLoading: false,
      isUploaderVisible: false,
      isAudioEnabled: true, // Estado inicial del audio, asumiendo que está activado por defecto
      threadId: null,
      retryTimeoutId: null,
      startTime: null,
      maxDuration: 180000, // 3 minutos
      profileImageUrl: '@/assets/images/logo-BS.png'
    };
  },
  methods: {
    toggleImageUploader() {
      this.isUploaderVisible = !this.isUploaderVisible;
    },
    toggleAudio(isEnabled) {
      this.isAudioEnabled = isEnabled;
      console.log("Audio toggled: ", this.isAudioEnabled); // Esto te ayudará a verificar que el estado se está actualizando correctamente.
    },
    async startConversation() {
      if (this.threadId) return;
      this.isLoading = true;
      try {
        const response = await fetch('https://brainstream.pro/api/starteo', { method: 'GET' });
        if (response.ok) {
          const data = await response.json();
          this.threadId = data.thread_id;
        } else {
          this.addMessage('Error al iniciar la conversación: ' + response.statusText, 'system');
          this.isLoading = false;
        }
      } catch (error) {
        this.addMessage('Error al iniciar la conversación: ' + error.message, 'system');
        this.isLoading = false;
      }
    },
    async sendPrompt(userPrompt) {
      if (!userPrompt.trim()) {
        this.addMessage('Por favor, escribe algo antes de enviar.', 'system');
        return;
      }
      this.isLoading = true;
      if (!this.threadId) await this.startConversation();
      try {
        const payload = { thread_id: this.threadId, message: userPrompt };
        const response = await fetch('https://brainstream.pro/api/chateo', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
        if (response.ok) {
          const data = await response.json();
          this.startTime = Date.now();
          this.addMessage(userPrompt, 'user');
          this.checkRunStatus(data.run_id);
        } else {
          const errorText = await response.text();
          this.addMessage(`Hubo un problema con la respuesta del servidor: ${response.statusText} - ${errorText}`, 'system');
          this.isLoading = false;
        }
      } catch (error) {
        this.addMessage('Error al enviar el mensaje: ' + error.message, 'system');
        this.isLoading = false;
      }
    },
    addMessage(content, sender) {
      this.messages.push({ content, sender });
    },
    async handleImageUpload(file) {
      if (!file) {
        this.addMessage('No se seleccionó ningún archivo de imagen.', 'system');
        return;
      }
      const formData = new FormData();
      formData.append('image', file);
      this.isLoading = true;
      try {
        const response = await fetch('https://brainstream.pro/api/upload-image', {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          if (data.analysis_result) {
            this.addMessage(`Imagen cargada y codificada con éxito.`, 'system');
            this.addMessage(`Imagen: ${data.analysis_result}`, 'system');
          } else {
            this.addMessage('No se pudo obtener la imagen codificada del servidor.', 'system');
          }
        } else {
          this.addMessage(`Error al cargar la imagen: ${response.statusText}`, 'system');
        }
      } catch (error) {
        this.addMessage(`Error al cargar la imagen: ${error.message}`, 'system');
      } finally {
        this.isLoading = false;
      }
    },
    async checkRunStatus(runId) {
      if (!this.threadId || !runId) {
        this.addMessage("Falta información del hilo o de la ejecución.", 'system');
        this.isLoading = false;
        return;
      }
      const elapsedTime = Date.now() - this.startTime;
      if (elapsedTime < this.maxDuration) {
        try {
          const response = await fetch(`https://brainstream.pro/api/checkeo`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ thread_id: this.threadId, run_id: runId }),
          });
          const data = await response.json();
          if (data.status === 'completed') {
            this.addMessage(data.response, 'system');
            this.isLoading = false;
            this.startTime = null;
          } else {
            this.retryTimeoutId = setTimeout(() => this.checkRunStatus(runId), 5000);
          }
        } catch (error) {
          this.addMessage(`Error al verificar el estado: ${error.message}`, 'system');
          this.isLoading = false;
          this.startTime = null;
        }
      } else {
        this.addMessage('La respuesta está tardando demasiado. Intenta nuevamente más tarde.', 'system');
        this.isLoading = false;
        this.startTime = null;
      }
    },
    clearRetryTimeout() {
      if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId);
      this.retryTimeoutId = null;
    }
  },
  beforeUnmount() {
    this.clearRetryTimeout();
  }
};
</script>

<style>
.chat-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
}

.audio-toggle-container {
  position: fixed; /* Posicionamiento fijo en la pantalla */
  top: 10px; /* Colocado en la parte superior */
  left: 10px; /* Colocado a la derecha */
  z-index: 1050; /* Alto z-index para asegurar que está por encima de otros componentes */
}

.message-history {
  flex-grow: 1;
  width: 100%;
  padding: 10px 0;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.message-input,
.voice-input {
  z-index: 1;
}

.toggle-uploader-btn {
  position: fixed;
  bottom: 40px;
  left: 5px;
  width: 40px; /* Tamaño del círculo */
  height: 40px; /* Tamaño del círculo */
  border-radius: 50%; /* Hace que el botón sea un círculo perfecto */
  background-color: #d4af37; /* Un tono de dorado que puedes ajustar */
  background-image: url('@/assets/icons/upload-icon.png'); /* Icono de subida */
  background-size: 20px 20px; /* Tamaño del icono dentro del botón */
  background-repeat: no-repeat;
  background-position: center; /* Centra el icono en el botón */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.toggle-uploader-btn:hover {
  background-color: #b39ddb; /* Un poco más oscuro al pasar el ratón */
  z-index: 100;
}
</style>
