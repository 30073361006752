// src/api.js
import axios from 'axios';

const API_URL = 'https://brainstream.pro/generate-audio';

export const generateAudio = async (text) => {
  try {
    console.log('Enviando solicitud a la API de generación de audio');
    const response = await axios.post(API_URL, { text }, { responseType: 'blob' });
    console.log('Respuesta recibida de la API:', response);
    return response.data;
  } catch (error) {
    console.error('Error al generar el audio:', error);
    throw error;
  }
};
