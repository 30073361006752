<template>
  <div class="welcome-component" :style="backgroundStyle">
    <div class="content">
      <h1>Welcome to My Digital Mind!!!!</h1>
      <div class="robot-container">
        <img :src="robotImage" alt="Digital Mind" class="floating-robot">
        <div class="speech-bubble">
          <p>How Can I Help You?</p>
        </div>
      </div>
      <div class="button-container">
        <button class="google-button" @click="loginWithGoogle">
          <img src="@/assets/images/google-icon.png" alt="Google Icon" class="icon"> Sign in with Google
        </button>
        <button class="guest-button" @click="getStarted">
          Continue as guest
        </button>
        <button class="guest-button" @click="privacyPolicy">
          Politica de Privacidad
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomePage',
  data() {
    return {
      robotImage: require('@/assets/images/personajes/digitalmind-01.png'),
      backgroundImage: require('@/assets/images/business-background.png'), // Asegúrate de que la ruta es correcta
    };
  },
  computed: {
    backgroundStyle() {
      // Aplica la imagen de fondo con una capa de color morado oscuro con transparencia
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: 'cover', // Cubre todo el espacio disponible
        backgroundPosition: 'center', // Centra la imagen de fondo
        backgroundColor: 'rgba(45, 31, 59, 0.85)', // Morado oscuro con transparencia
        backgroundBlendMode: 'overlay', // Mezcla la imagen con el color
      };
    }
  },
  methods: {
    getStarted() {
      // Implementar la lógica de navegación aquí
      window.location.href = 'https://brainstream.pro/contacto'; // Cambia a la navegación interna si es necesario
    },
    loginWithGoogle() {
      // Redirige a la URL de inicio de sesión con Google
      window.location.href = 'https://brainstream.pro/api/login';
    },
    privacyPolicy() {
      // Redirige a la URL de la politica de privacidad 
      window.location.href = 'https://brainstream.pro/privacy';
    }
  }
};
</script>

<style scoped>
.welcome-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: white;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
  position: relative;
}

h1 {
  z-index: 10;
  position: relative;
  margin-bottom: 1rem;
}

.robot-container {
  max-width: 300px;
  position: absolute;
  top: 55%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.floating-robot {
  position: relative;
  bottom: 150%;
  width: 80%;
  right: 20%;
  animation: float-robot 3s ease-in-out infinite;
  display: block;
  z-index: 10;
}

.speech-bubble {
  position: absolute;
  bottom: 150%;
  transform: translate(-50%, 50%);
  background: rgba(0, 63, 114, 0.7);
  border-radius: 25px;
  padding: 1rem 2rem;
  color: white;
  font-size: 0.9em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  animation: float-bubble 3.5s ease-in-out infinite;
  z-index: 6;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.google-button, .guest-button {
  padding: 1rem 1rem;
  border: none;
  border-radius: 50px;
  background-color: white;
  color: #555;
  font-size: 0.7em;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.google-button:hover, .guest-button:hover {
  background-color: #f0f0f0;
}

.icon {
  width: 24px;
  height: 24px;
}

@keyframes float-robot {
  0%, 100% { transform: translate(-50%, -50%); }
  50% { transform: translate(-50%, calc(-50% - 20px)); }
}

@keyframes float-bubble {
  0%, 100% { transform: translate(-50%, 0); }
  50% { transform: translate(-50%, -10px); }
}
</style>
