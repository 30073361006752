<template>
  <div class="qr-scan-container">
    <h1>Escaneo de QR</h1>
    <p>Por favor, posiciona el código QR frente a la cámara.</p>
    <div class="video-canvas-container">
      <!-- Asegúrate de que la etiqueta del componente coincide con el nombre registrado en la sección 'components' -->
      <camera-qr-component :is-active="scanning" @camera-error="handleCameraError" />
    </div>
    <div v-if="cameraError" class="error-message">
      Error: No se pudo acceder a la cámara. Por favor, verifica los permisos y recarga la página.
    </div>
    <button @click="toggleScanning">
      {{ scanning ? 'Detener Escaneo' : 'Iniciar Escaneo' }}
    </button>
  </div>
</template>

<script>
import CameraQRComponent from './CameraQRComponent.vue';

export default {
  components: {
    'camera-qr-component': CameraQRComponent // Aquí se registra usando camelCase y se usa en kebab-case
  },
  data() {
    return {
      scanning: false,
      cameraError: false
    };
  },
  methods: {
    toggleScanning() {
      this.scanning = !this.scanning;
    },
    handleCameraError() {
      this.cameraError = true;
      this.scanning = false; // Detiene el escaneo si hay un error de cámara
    }
  }
};
</script>

<style scoped>
.qr-scan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;
  background-color: #f0f0f0; /* Color de fondo claro */
}

.video-canvas-container {
  width: 100%;
}

.error-message {
  color: red;
  margin: 20px 0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
