<template>
  <div class="welcome-page">
    <div class="welcome-content">
      <img src="@/assets/images/Logo-TheAdventure.png" alt="Logo" class="logo">
      <h1>Bienvenidos a Nuestro Sitio</h1>
      <p>Let's Make Our Life Better</p>
      <router-link to="/explorar" class="explore-button">Ver mas</router-link>
      
      <div class="image-grid-container">
        <router-link class="grid-item" v-for="image in images" :key="image.alt" :to="image.route">
          <img :src="image.src" :alt="image.alt"/>
        </router-link>
      </div>

      <div class="about-us">
        <h2>Sobre nosotros</h2>
        <p>Somos una comunidad dedicada a explorar la naturaleza y disfrutar de la vida al aire libre. Nuestro objetivo es inspirar a otros a vivir experiencias únicas y emocionantes en la naturaleza.</p>
      </div>
      <PromptComponent />
    </div>
  </div>
</template>

<script>
import PromptComponent from '@/components/PromptComponent.vue';

export default {
  name: 'WelcomePage',
  components: {
    PromptComponent,
  },
  data() {
    return {
      images: [
        { src: require('@/assets/images/cabins.jpg'), alt: 'Image 1', route: '/home' },
        { src: require('@/assets/images/nature.jpg'), alt: 'Image 2', route: '/contacto' },
        { src: require('@/assets/images/auroras.jpg'), alt: 'Image 3', route: '/otra-ruta' },
        // Agrega más imágenes y sus rutas según sea necesario
      ],
    };
  }
};
</script>

<style>
.explore-button {
  /* Tus estilos para .explore-button aquí */
  display: inline-block; /* Asegura que se comporte como un enlace */
  text-decoration: none; /* Elimina subrayado de enlaces */
  color: inherit; /* Mantiene el color del texto igual al del contenedor */
  /* Otros estilos que requieras */
}
</style>
