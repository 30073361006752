<template>
  <div class="message-input">
    <textarea v-model="userPrompt" 
              placeholder="Escribe algo..." 
              @keyup.enter="sendPrompt" 
              :disabled="isLoading"
              autofocus></textarea>
    <button :disabled="isLoading || !userPrompt.trim()" @click="sendPrompt">
      <span v-if="isLoading" class="loading-dots"></span>
      <i v-else class="fas fa-arrow-right"></i> <!-- Ícono de flecha de FontAwesome -->
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userPrompt: ''
    };
  },
  methods: {
    sendPrompt() {
      this.$emit('send', this.userPrompt);
      this.userPrompt = ''; // Clear the textarea after sending
    }
  }
};
</script>

<style scoped>
.message-input {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.message-input textarea {
  flex: 1; /* Ocupa todo el espacio disponible menos el del botón */
  min-height: 40px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 16px;
  border: 1px solid #d4af37;
  border-radius: 10px;
  transition: border-color 0.3s, background 0.3s; /* Suaviza la transición de fondo y borde */
  outline: none; /* Elimina el contorno al enfocar para un diseño más limpio */
  margin-right: 10px; /* Espacio entre el textarea y el botón */
  margin-left: 40px; /* Espacio entre el textarea y el botón */
}

.message-input textarea:focus {
  border-color: #a1d6a3; /* Color más suave para el foco */
  background: #FFFFFF; /* Fondo blanco al enfocar */
}

.message-input button {
  width: 40px; /* Tamaño fijo para mantener el círculo perfecto */
  height: 40px; /* Igual al ancho para hacer el botón perfectamente circular */
  padding: 0; /* Sin padding adicional para que el icono quede centrado */
  background-color: #d4af37;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%; /* Hace el botón circular */
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; /* Asegura que el botón esté al final del espacio del textarea */
  margin-right: 20px; /* Añade más espacio al lado derecho para evitar tocar el borde de la pantalla */
}

.message-input button:hover,
.message-input button:focus {
  background-color: #b39ddb;
  outline: none;
}

.loading-dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: loading-animation 1s infinite;
}

@keyframes loading-animation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
