<template>
  <div>
    <ChatComponent />
  </div>
</template>

<script>
import ChatComponent from '@/components/TriunfaLibro.vue';

export default {
  components: {
    ChatComponent
  }
};
</script>

<style>
/* Aquí puedes definir estilos globales si es necesario */
</style>
