<template>
  <div class="background">
    <div class="conversations-container">
      <h2>Conversaciones recientes</h2>
      <div v-for="(group, index) in groupedConversations" :key="index" class="conversation-group">
        <h3>Thread ID: {{ group.thread_id }}</h3>
        <div class="conversation-card" v-for="(message, idx) in group.messages" :key="idx">
          <div class="message-header">
            <strong>Mensaje Usuario:</strong>
            <p>{{ message.user_message }}</p>
          </div>
          <div class="message-body">
            <strong>Respuesta Asistente:</strong>
            <p>{{ message.assistant_response }}</p>
          </div>
          <div class="message-footer">
            <small><strong>Timestamp:</strong> {{ message.timestamp }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conversations: [], // Para almacenar las conversaciones obtenidas
    };
  },
  computed: {
    groupedConversations() {
      // Agrupar las conversaciones por thread_id
      const grouped = this.conversations.reduce((acc, conversation) => {
        const thread = acc.find(g => g.thread_id === conversation.thread_id);
        if (thread) {
          thread.messages.push(conversation);
        } else {
          acc.push({
            thread_id: conversation.thread_id,
            messages: [conversation]
          });
        }
        return acc;
      }, []);
      return grouped;
    }
  },
  mounted() {
    // Llamar al backend cuando el componente esté montado
    this.fetchConversations();
  },
  methods: {
    fetchConversations() {
      fetch('https://brainstream.pro/api/conversations') // Ajustar la URL según sea necesario
        .then(response => response.json())
        .then(data => {
          this.conversations = data;
        })
        .catch(error => {
          console.error('Error al obtener las conversaciones:', error);
        });
    }
  }
};
</script>

<style scoped>
/* Estilos generales */
body, html {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Evitar que el fondo haga scroll */
}

.background {
  background-color: #f8f9fa;
  width: 100vw;
  height: 100vh;
  position: fixed; /* Mantener el fondo fijo */
  top: 0;
  left: 0;
}

.conversations-container {
  height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-y: auto; /* Permitir scroll solo en el contenido */
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.conversation-group {
  margin-bottom: 40px;
}

h3 {
  background-color: #4a90e2;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

/* Estilo de la tarjeta */
.conversation-card {
  background-color: #f1f1f1;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-header, .message-body {
  font-size: 1.1rem;
  margin-bottom: 12px;
  line-height: 1.6;
  color: #555;
}

.message-header p, .message-body p {
  margin: 0;
  padding-left: 10px;
  border-left: 4px solid #4a90e2;
}

.message-footer {
  font-size: 0.9rem;
  color: #666;
}

strong {
  color: #333;
}

small {
  color: #999;
}

/* Animación hover para las tarjetas */
.conversation-card:hover {
  transform: translateY(-2px);
  transition: all 0.3s ease-in-out;
  background-color: #eaeaea;
}

/* Responsividad */
@media (max-width: 1024px) {
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  .message-header, .message-body {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
    padding: 8px;
  }
  .message-header, .message-body {
    font-size: 0.9rem;
  }
  .conversations-container {
    padding: 15px; /* Ajustar el padding en tablets */
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.1rem;
    padding: 5px;
  }
  .message-header, .message-body {
    font-size: 0.85rem;
  }
  .conversations-container {
    padding: 10px; /* Ajustar el padding para móviles */
  }
}
</style>
