import { createStore } from 'vuex';

// Crea una nueva store instance.
const store = createStore({
  state () {
    return {
      // define tus estados aquí, por ejemplo:
      count: 0
    }
  },
  mutations: {
    // define tus mutaciones aquí
  },
  actions: {
    // define tus acciones aquí
  },
  modules: {
    // define tus módulos aquí si tienes
  }
});

export default store;
