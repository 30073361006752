<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">
    <div class="background-container"></div>
    <BottomNav />
    <router-view/>
  </div>
</template>

<script>
</script>

<style>
  body, button, h1, p {
    font-family: 'Montserrat', sans-serif;
  }

  .welcome-page {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Alinea los elementos al principio del contenedor verticalmente */
    text-align: center;
    min-height: calc(100vh - 50px); /* Asegúrate de que la altura de la barra de navegación esté considerada aquí */
    overflow-y: auto; /* Permite el desplazamiento vertical si el contenido excede el tamaño del contenedor */
    padding-top: 30%; /* Agrega un espacio desde la parte superior de la página */
    position: relative; /* Asegura que el contenido no se desborde sobre el fondo */
  }

  /* Estilo para el fondo */
  .background-container {
    position: fixed; /* Fija el contenedor del fondo en la ventana */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Coloca el fondo detrás del contenido */
    background-image: url('@/assets/images/auroras.jpg'); 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  .welcome-content {
    box-sizing: border-box;
    max-width: 100%; /* Ajusta al 100% del ancho de la pantalla */
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra verticalmente el contenido */
    height: auto; /* Ajusta la altura según el contenido */
    line-height: 1.0; /* Ajusta el espaciado entre líneas del texto */
    color: white; /* Cambia el color del texto a blanco */
    padding-bottom: 60px;
  }

  h1 {
    font-size: 2rem; /* Ajusta según necesites */
    margin-bottom: 30px; /* Incrementa el margen inferior si es necesario */
  }

  p {
    font-size: 1rem; /* Ajusta según necesites */
    margin-bottom: 30px; /* Incrementa el margen inferior si es necesario */
  }

  .explore-button {
    font-size: 1rem;
    padding: 15px 30px; /* Ajusta el padding para hacer el botón más grande */
    margin-top: 20px; /* Añade un margen superior para separarlo del texto */
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px; /* Añade un margen inferior para separar del grid */
  }

  .explore-button:hover {
    background-color: #45a049;
  }

  .logo {
    margin-top: 20%; /* Agrega espacio entre la parte superior de la página y el logotipo */
    max-width: 40%;
    margin: 0 auto; /* Centra el logo horizontalmente */
    display: block; /* Asegura que el logo se trate como un bloque para que margin: auto funcione */
  }

  /* Agregamos estilos para el grid de imágenes */
  .image-grid-container {
    overflow-x: auto; /* Permite el desplazamiento horizontal */
    overflow-y: hidden; /* Evita el desplazamiento vertical */
    white-space: nowrap; /* Evita el salto de línea en los elementos del grid */
    scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .image-grid-container::-webkit-scrollbar {
    display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Opera */
  }

  /* Ajustes para los elementos dentro del contenedor del grid */
  .grid-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Basado en la relación de aspecto 4:5, ajusta este valor según necesites */
    height: 250px; /* Basado en la relación de aspecto 4:5, ajusta este valor según necesites */
    overflow: hidden; /* Oculta cualquier parte de la imagen que exceda este contenedor */
    border-radius: 5px; /* Bordes redondeados para el contenedor */
    margin-right: 20px; /* Espacio entre elementos */
  }

  .grid-item img {
    height: 100%; /* Asegura que la altura de la imagen siempre llene el contenedor */
    width: auto; /* Mantiene la proporción de la imagen */
    object-fit: cover; /* Cubre completamente el contenedor, posiblemente recortando la imagen */
    object-position: center; /* Centra la imagen dentro del contenedor */
  }

  .grid-item:last-child {
    margin-right: 0; /* Elimina el margen derecho del último elemento */
  }

  .grid-item img:hover {
    transform: scale(1.05); /* Efecto zoom al pasar el cursor */
  }

  .prompt-component {
  margin-bottom: 30px; /* Ajusta según la altura de tu barra de navegación */
  }
</style>
