<template>
  <div class="voice-input">
    <!-- Aseguramos que el evento click está en el botón y maneja la grabación -->
    <button @click="toggleRecording" :disabled="isRecording">
      <!-- Añadimos un icono de micrófono. Asegúrate de que la ruta al ícono es correcta -->
      <img src="@/assets/icons/microphone-icon.png" alt="Microphone" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRecording: false,
      recognition: null  // Almacenar el objeto de reconocimiento de voz aquí
    };
  },
  methods: {
    toggleRecording() {
      this.isRecording ? this.stopRecording() : this.startRecording();
    },
    startRecording() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        console.error('La API de reconocimiento de voz no es soportada en este navegador.');
        return;
      }
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'es-ES';
      this.recognition.continuous = false;
      this.recognition.interimResults = false;

      this.recognition.onstart = () => {
        this.isRecording = true;
      };

      this.recognition.onresult = event => {
        const transcript = event.results[0][0].transcript;
        this.$emit('send', transcript);
        this.stopRecording();
      };

      this.recognition.onerror = event => {
        console.error('Error en la grabación: ', event.error);
        this.stopRecording();
      };

      this.recognition.start();
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
        this.recognition = null;
        this.isRecording = false;
      }
    }
  }
};
</script>

<style scoped>
.voice-input {
  position: fixed;
  bottom: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d4af37;  /* Mantiene el estilo dorado */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.voice-input button {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voice-input button img {
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.voice-input button:disabled {
  background-color: #b39ddb;
  border-radius: 50%;
  bottom: 5px;
  left: 5px;
}

.voice-input:hover {
  background-color: #b39ddb;
}
</style>
